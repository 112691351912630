footer:
  menu:
    home: Home
    terms: Terms & Conditions
    cookies_policy: Privacy Policy
    contact_us: Contact us
Email or password is incorrect: Email or password is incorrect
Something went wrong. Please try again later: Something went wrong. Please try again later
Logout: Logout
Profile Settings:  Profile Settings
Terms of Service: Terms of Service
Setup: Setup
Admin: Admin
Users: Users
Privacy Policy: Privacy Policy
Contact Us: Contact Us
Product Suggestion: Product Suggestion
Dashboard: Dashboard
Help and support: Help and support
Send us feedback: Send us feedback
Hello: Hello
Analytics: Analytics
Reports: Reports
Transactions: Transactions
Discounts: Discounts
Employee sales: Employee sales
Gift card: Gift card
Items sales by category: Items sales by category
Items sales: Items sales
Net sales: Net sales
Sales & revenue: Sales & revenue
Sales per hour: Gross sales per hour
Voids, refunds & corrections: Voids, refunds & corrections
Financial: Financial
Audit: Audit
Category sales: Category sales
Discount: Discount
Location sales: Location sales
Summary: Summary
Check: Check
Check summary: Check summary
Check search: Check search
Menu: Menu
Categories: Categories
Sub-categories: Sub-categories
Items: Items
Item profile: Item profile
Popups: Popups
Combos: Combos
Mix and match: Mix and match
Coursing: Coursing
Concepts: Concepts
Counters: Counters
Business: Business
Taxes: Taxes
Settings: Settings
Locations: Locations
Groups: Groups
Resellers: Resellers
Employees: Employees
Coupons: Coupons
Language: Language
System: System
Location status: Location status
Check the status of all locations for the selected groups.: Check the status of all locations for the selected groups.
Create, configure, or deactivate current users.: Create, configure, or deactivate current users.
Browse and configure your locations.: Browse and configure your locations.
Browse and configure your groups.: Browse and configure your groups.
Browse and configure your resellers.: Browse and configure your resellers.
Create, configure, or deactivate current employees.: Create, configure, or deactivate current employees.
Browse and configure your coupons.: Browse and configure your coupons.
View overall statistics.: View overall statistics.
Search for specific checks and details.: Search for specific checks and details.
Review check summaries for a groupe, location, and given period of time.: Review check summaries for a group, location, and given period of time.
Browse and configure your categories: Browse and configure your categories.
Browse and configure your sub-categories.: Browse and configure your sub-categories.
Browse and configure your items.: Browse and configure your items.
Browse and configure your item profiles.: Browse and configure your item profiles.
Browse and configure your popups.: Browse and configure your popups.
Browse and configure your combos.: Browse and configure your combos.
Browse and configure your mix and match.: Browse and configure your mix and match.
Browse and configure your coursings.: Browse and configure your coursings.
Browse and configure your concepts.: Browse and configure your concepts.
Browse and configure your counters.: Browse and configure your counters.
Configure your business.: Configure your business.
Browse and configure your taxes.: Browse and configure your taxes.
Browse and configure your tax categories.: Browse and configure your tax categories.
Browse and configure your discounts.: Browse and configure your discounts.
Drag and drop the sections to create your own dashboard.: Drag and drop the sections to create your own dashboard.
Generate a report to browse activated discounts.: Generate a report to browse activated discounts.
Generate a report to browse employee sales.: Generate a report to browse employee sales.
Generate a report to browse gift card transactions.: Generate a report to browse gift card transactions.
Generate a report to browse sales by categories.: Generate a report to browse sales by categories.
Generate a report to browse items sales.: Generate a report to browse items sales.
Review your net sales details by clicking on the items in this table.: Review your net sales details by clicking on the items in this table.
Generate a report to browse sales & revenue.: Generate a report to browse sales & revenue.
Generate a report to browse sales per hour.: Generate a report to browse sales per hour.
Generate a report to browse voids, refunds, and corrections.: Generate a report to browse voids, refunds, and corrections.
Generate a report to browse audits.: Generate a report to browse audits.
Generate a report to browse category sales.: Generate a report to browse category sales.
Generate a report to browse sales by locations.: Generate a report to browse sales by locations.
Generate an easy-to-read general summary.: Generate an easy-to-read general summary.
Explore your latest reports.: Explore your latest reports.
Review your financial reports: Review your financial reports.
Review your transaction reports: Review your transaction reports.
Review your check reports: Review your check reports.
Access: Access
Browse and configure your menu.: Browse and configure your menu.
Configure your settings.: Configure your settings.
Browse and configure your categories.:  Browse and configure your categories.
Email: Email
First name: First name
Last name: Last name
Type: Type
Enabled: Enabled
Reseller name: Reseller name
Created: Created
Display: Display
items: items
Display of: Display of
to: to
of: of
Go to page: Go to page
Edit: Edit
Delete: Delete
Active: Active
Inactive: Inactive
Merchant: Merchant
Payfacto employee: Payfacto employee
Agent: Agent
users: users
locations: locations
Logo: Logo
Group: Group
Name: Name
Reporting display name: Reporting display name
Address: Address
Country: Country
License: License
Version: Version
External ID: External ID
Last invoice: Last check
Canada: Canada
United Kingdom: United Kingdom
Bahamas: Bahamas
Guadeloupe: Guadeloupe
United States: United States
groups: groups
Corporate: Corporate
Banner: Banner
Custom: Custom
Online: Online
Warning: Warning
Offline: Offline
POS Type: POS Type
Last activity: Last activity
Status: Status
Hide filters: Hide filters
Show filters: Show filters
License number: License number
POS version: POS Version
Location: Location
Manage filters: Manage filters
Apply: Apply
Clear: Clear
All status: All status
All POS type: All POS Type
OK: OK
Cancel: Cancel
Select all: Select all
Deselect all: Deselect all
Oops!: Oops!
It seems there are no results for your search. Try again with different filters.: It seems there are no results for your search.<br />Try again with different filters.
Group name: Group name
Group type: Group type
Enter name: Enter name
All types: All types
Select one or more groups: Select one or more groups
Enable multiselect to choose more than one or all groups.: Enable multiselect to choose more than one or all groups.
Multiselect: Multiselect
All groups: All groups
Download as PDF: Download as PDF
Download as Excel: Download as Excel
Download as JSON: Download as JSON
Send by email: Send by email
Select a POS type: Select a POS type
"12345": 12345
1.2: 1.2
10: 10
Select a status: Select a status
Refresh: Refresh
Show selected items only: Show selected items only
Enter email address: Enter email address
Email address: Email address
Full name: Full name
Create new user: Create new user
Create new group: Create new group
Enter the required information to create a new group.: Enter the required information to create a new group.
Wait...: Wait...
Nothing to see here for now. Select a group to generate a coupons list.: Nothing to see here for now. Select a group to generate a coupons list.
Enter coupon name: Enter coupon name
Enter coupon code: Enter coupon code
Code: Code
Amount: Amount
Multi: Multi
Single: Single
Multiple: Multiple
Expiry: Expiry
Redeemed: Redeemed
coupons: coupons
indicators:
  drawerOpenings:
    title: No Sales count
    description: Count of no sales transactions (open drawer).
  giftCardAmount:
    title: Gift card loads amount
    description: Sum of gift card loads.
  itemCorrections:
    title: Item corrections count
    description: Count of item corrections.
  itemCorrectionsAmount:
    title: Item corrections amount
    description: Sum of item corrections.
  loyaltiesAmount:
    title: Loyalties amount
    description: Total amount of loyalty redemptions.
  paidOuts:
    title: Paid outs
    description: Number of paid outs.
  paidOutsAmount:
    title: Paid outs amount
    description: Sum of the amounts paid to suppliers.
  refunds:
    title: Refunds count
    description: Count of refunds.
  refundsAmount:
    title: Refunds amount
    description: Sum of refunds.
  voids:
    title: Voids count
    description: Count of voids.
  voidsAmount:
    title: Voids amount
    description: Sum of voids.
  grossSalesAmount:
    title: Gross sales amount
    description: Sum of invoiced sales (excluding taxes) before application of discounts (global and items).
  discountsAmount:
    title: Discounts amount
    description: Sum of global and item discounts.
  invoicesDiscount:
    title: Checks discount
    description: Total amount of checks discount.
  itemsDiscount:
    title: Items discount
    description: Total amount of items discount.
  netSalesAmount:
    title: Net sales amount
    description: Sum of invoiced sales (excluding taxes) after application of discounts.
  refundsTotalAmount:
    title: Refunds total amount
    description: Total amount of refunds.
  taxesAmount:
    title: Taxes amount
    description: Sum of taxes.
  gratuityAmount:
    title: Gratuity amount
    description: Sum of gratuities.
  tipsAmount:
    title: Tips amount
    description: Sum of tips.
  tipsTotalAmount:
    title: Tips total amount
    description: Total amount of tips.
  customers:
    title: Customers count
    description: Count of customers.
  meals:
    title: Meals count
    description: Count of meals sold, based on item meal count configuration.
  orders:
    title: Checks count
    description: Count of checks.
  debitCreditTotalAmount:
    title: Debit credit total amount
    description: Total amount of debit credit.
  debitCreditTipsAmount:
    title: Debit credit tips amount
    description: Total amount of debit credit tips.
  averageNetSales:
    title: Net income per check
    description: Income per check calculated on net sales.
  averageGrossSales:
    title: Gross income per check
    description: Income per check calculated on gross sales.
  roundingAmount:
    title: Rounding amount
    description: Sum of rounding.
  cashTipsAmount:
    title: Cash tips amount
    description: Total amount of cash tips.
  surchargeAmount:
    title: Surcharge amount
    description: Sum of the surcharges.
  netCashAmount:
    title: Net cash amount
    description: Sum of cash tender type, plus rounding if applicable.
  cashDepositAmount:
    title: Cash deposit amount
    description: Sum of gross sales paid in cash, less discounts and tips.
  paidInsAmount:
    title: Paid ins amount
    description: Sum of the paid ins.
  cashDiscountAmount:
    title: Cash discount amount
    description: Sum of cash discount.
  locationSales:
    title: Location sales
    description: Top 10 locations by sales.
  itemsSales:
    title: Items sales
    description: Top 10 items by sales.
  tenderTypesSales:
    title: Tender types
    description: Tender types.
  departmentsSales:
    title: Gross sales per revenue center
    description: Gross sales per revenue center.
  zonesSales:
    title: Gross sales per order mode
    description: Order mode sales.
  employeesSales:
    title: Gross sales per employee
    description: Gross sales per employee.
  discountsSales:
    title: Detailed discounts
    description: Detailed discounts.
  workstationsSales:
    title: Gross sales per workstation
    description: Gross sales per workstation.
  bigDivisionsSales:
    title: Gross sales per category
    description: Gross sales per category.

  modesSales:
    title: Gross sales per mode
    description: Gross sales per mode.
  modesCustomers:
    title: Number of customers per mode
    description: Number of customers per mode.
  modesIncomeCustomers:
    title: Income per customers by mode
    description: Income per customers by mode.
  hourlySales:
    title: Gross sales per hour
    description: Gross sales per hour.
  cumulativeHourlySales:
    title: Cumulative gross sales per hour
    description: Cumulative gross sales per hour.
  grossSalesPerMeal:
    title: Gross income per meal
    description: Income per meal calculated on gross sales.
  netSalesPerMeal:
    title: Net income per meal
    description: Income per meal calculated on net sales.
  discountsPercentage:
    title: Discounts percentage
    description: Discounts percentage calculated on gross sales for global and item discounts.
  grossSalesPerCustomer:
    title: Gross income per customer
    description: Income per customer calculated on gross sales.
  netSalesPerCustomer:
    title: Net income per customer
    description: Income per customer calculated on net sales.
  consolidatedHourlySales:
      title: Gross sales per hour
      description: Gross sales per hour
CAD: CAD
Show data trends: Show data trends
Customization: Customization
Manage your dashboard information. You can enable and disable informations.: Manage your dashboard information. You can enable and disable informations.
indicator: indicator
Reset dashboard: Reset dashboard
Select a group: Select a group
Enter license: Enter license
Enter version: Enter version
Enter address: Enter address
Enter display name: Enter display name
Enter location name: Enter location name
Enter group name: Enter group name
Location name: Location name
Display name: Display name
All: All
Add new: Add new
Enter the required information to create a new user.: Enter the required information to create a new user.
User status: User status
Create user: Create user
Back: Back
Sorry, an error has occurred. Please try again.: Sorry, an error has occurred. Please try again.
Select type: Select type
Reset password: Reset password
Modify: Modify
Save: Save
General information: General information
Contact information and user type.: Contact information and user type.
User type: User type
Reseller: Reseller
Phone number: Phone number
Extension: Extension
Select a reseller: Select a reseller
Back to users: Back to users
Save & quit: Save & quit
test: test
user_edit_sub_title: "Created by {username} / Last update {last_update}"
Success: Success
User updated successfully.: User updated successfully.
Restrict or expand the user access to back-end functions.: Restrict or expand the user access to back-end functions.
User permissions: User permissions
Select a location: Select a location
Select a permission: Select a permission
Full access: Full access
Add permission: Add permission
Create a password reset token.: Send a password reset email to a user.
Request successfully completed.: Request successfully completed.
User created successfully.: User created successfully.
Are you sure you want to create a password reset token for this user?: Are you sure you want to reset this user’s password? Doing so will send a reset password email to this address.
Are you sure you want to delete the %s group? This operation will also delete the following information related to this group: Are you sure you want to delete the <b>{name}</b> group? This operation will also delete the following information related to this group
Warning!: Warning!
Products (ex coupons): "Products (ex: coupons)"
Links to locations: Links to locations
To delete this group, please enter the name %s. in the input below and click Delete group.: To delete this group, please enter the name <b>{name}</b> in the input below and click Delete group.
Delete group: Delete group
The new user has been created successfully.: The new user has been created successfully.
The changes have been saved successfully.: The changes have been saved successfully.
The password reset token has been sent successfully.: The password reset token has been sent successfully.
The group has been deleted successfully.: The group has been deleted successfully.
Group's information and logo.: Group's information and logo.
Enter or edit the information, permissions, and locations for this group.: Enter or edit the information, permissions, and locations for this group.
Group configuration: Group configuration
Access the list of users with permissions for this group.: Access the list of users with permissions for this group.
Back to groups: Back to groups
Add location(s): Add location(s)
Select and add one or more location(s) to this group.: Select and add one or more location(s) to this group.
Location(s): Location(s)
Select one or more: Select one or more
User configuration: User configuration
Enter or edit the information and permissions for this user.: Enter or edit the information and permissions for this user.
The new group has been created successfully.: The new group has been created successfully.
Get access to the settings and configuration for the entire Veloce content.: Get access to the settings and configuration for the entire Veloce content.
Manage and regroup the various entities that are utilizing the Veloce solution.: Manage and regroup the various entities that are utilizing the Veloce solution.
Access status and other information.: Access status and other information.
An error has occurred or your link has expired.: An error has occurred or your link has expired.
Your password has been successfully updated.: Your password has been successfully updated.
Weak: Weak
Medium: Medium
Strong: Strong
Pick a password: Pick a password
All locations: All locations
Enter the required information to create a new coupon.: Enter the required information to create a new coupon.
Create new coupon: Create new coupon
Usage: Usage
Select usage: Select usage
Coupon settings: Coupon settings
PLU 1: PLU 1
PLU 1 Amount: PLU 1 Amount
PLU 2: PLU 2
PLU 2 Amount: PLU 2 Amount
Activate coupon: Activate coupon
Enter expiration: Enter expiration
The coupon has been created successfully.: The coupon has been created successfully.
Invalid column name: Invalid column name at pos {column}.<br><br>"{expected}" <> "{current}".
Your CSV file is empty: Your CSV file is empty.
Invalid code length: Invalid data in the Code column on line length at line {line}.
Missing name: Missing name at line {line}.
Invalid value in column Active: Invalid value in column Active at line {line}.
Invalid value in column Plu1: Invalid value in column Plu1 at line {line}.
Invalid value in column Plu1Amount: Invalid value in column Plu1Amount at line {line}.
Invalid value in column Plu2Amount: Invalid value in column Plu2Amount at line {line}.
Invalid value in column Coupon type: Invalid value in column Coupon type at line {line}.
Invalid value in column Expires: Invalid value in column Expires at line {line}.
Import coupons: Import coupons
Download: Download
Select a file: Select a file
Choose: Choose
You can import multiple coupons by uploading a file in CSV format. The file must contain the following columns: You can import multiple coupons by uploading a file in CSV format. The file must contain the following columns
CSV file should contain following columns: CSV file should contain following columns
between 3 and 12 characters: between 3 and 12 characters
accepted values true, false, 0, 1: "accepted values: true, false, 0, 1"
0 = multi, 1 = single: "0 = multi, 1 = single"
minLength: Should be at least {min} characters long
maxLength: Should be at least {max} characters long
Enter amount: Enter amount
required: Required
The coupons has been imported successfully: "{ count } coupons has been imported successfully"
The coupons has been deleted successfully: "{ count } coupons has been deleted successfully"
PLU1: PLU1
PLU2: PLU2
You can delete multiple coupons by uploading a file in CSV format: You can delete multiple coupons by uploading a file in CSV format.
Delete coupons: Delete coupons
The coupon has been updated successfully.: The coupon has been updated successfully.
The coupon has been deleted successfully.: The coupon has been deleted successfully.
Update coupon: Update coupon
Create coupon: Create coupon
Edit the information and settings of this coupon.: Edit the information and settings of this coupon.
Coupon configuration: Coupon configuration
Confirm: Confirm
Are you sure you want to use this file and delete coupons?: Are you sure you want to use this file and delete <b>{totalLength}</b> coupons?
Are you sure you want to use this file and import coupons?: Are you sure you want to use this file and import <b>{totalLength}</b> coupons?
fullAccess: Full access
Permission type: Permission type
Are you sure you want to delete this permission?: Are you sure you want to delete this permission?
Delete permission: Delete permission
Are you sure you want to delete this coupon?: Are you sure you want to delete this coupon?
Delete coupon: Delete coupon
Coupon redemption: Coupon redemption
Redemption date: Redemption date
Single use coupon was reactivated on _ by _.: Single use coupon was reactivated on <b>{date}</b> by <b>{username}</b>.
Currency: Currency
Demo location: Demo location
Make this location a demonstration.: Make this location a demonstration
Access the list of users with permissions for this location.: Access the list of users with permissions for this location.
Logo for this location: Logo for this location
Enter or edit the information and permissions for this location.: Enter or edit the information and permissions for this location.
Location configuration: Location configuration
Back to locations: Back to locations
Location's information and logo.: Location's information and logo.
location_edit_sub_title: "Last update {last_update}"
Location groups: Location groups
Add or remove this location from groups.: Add or remove this location from groups.
Add to group: Add to group
Remove: Remove
Select a group you wish to add this location to.: Select a group you wish to add this location to.
Are you sure you want to delete this group?: Are you sure you want to delete this group?
The location has been successfully detached from the group.: The location has been successfully removed from the group.
The location has been successfully attached to the group.: The location has been successfully added to the group.
Detach location: Remove location
Are you sure you want to detach this location from this group?: Are you sure you want to remove this location from this group?
group: Group
location: Location
Edit the cataloging information and activate/deactivate this coupon.: Edit the cataloging information and activate/deactivate this coupon.
If this coupon is available via your POS station, enter the Price Look-up code (PLU) of the veloce item added to the client order upon ordering the coupon.: If this coupon is available via your POS station, enter the Price Look-up code (PLU) of the veloce item added to the client order upon ordering the coupon.
Redeeming a coupon means that the coupon has been used by a client to a location. You can view when and where the coupon has been redeemed in this section.: Redeeming a coupon means that the coupon has been used by a client to a location. You can view when and where the coupon has been redeemed in this section.
Redemption location: Redemption location
Coupon redeemed: Coupon redeemed
Closed: Closed
Voided: Voided
Training: Training
Date/Time: Date/Time
Cust.: Cust.
Employee: Employee
Empl. No.: Empl. No.
Tender: Tender
Surcharge: Surcharge
Tip: Tip
Service: Service
Table: Table
Check number(s): Check number(s)
Tender type(s): Tender type(s)
POS number(s): POS number(s)
Revenue center(s): Revenue center(s)
Discounts(s): Discounts(s)
Enter number: Enter number
Date range: Date range
Details: Details
Customer(s): Customer(s)
Revenue center: Revenue center
Time: Time
Employee no.: Employee no.
Qty: Qty
Additional fees: Additional fees
Tender types: Tender types
Search filters: Search filters
Subtotal: Subtotal
Generate: Generate
Quit without saving: Quit without saving
Your changes will be lost if you do not save them.: Your changes will be lost if you do not save them.
Would you like to save the changes made to the location %s?:  Would you like to save the changes made to the location <b>{name}</b>?
Would you like to save the changes made to the user %s?:  Would you like to save the changes made to the user <b>{firstName} {lastName}</b>?
Would you like to save the changes made to the group %s?:  Would you like to save the changes made to the group <b>{name}</b>?
Would you like to save the changes made to the coupon %s?:  Would you like to save the changes made to the coupon <b>{code}</b>?
Nothing to see here for now. Enter the required information to generate a check summary.:  Nothing to see here for now. Enter the required information to generate a check summary.
Nothing to see here for now. Enter the required information to launch a check search.:  Nothing to see here for now. Enter the required information to launch a check search.
Jamaica: Jamaica
India: India
Select country: Select country
Permissions: Permissions
Select a type: Select a type
Contact information: Contact information
Enter the information needed to catalog and contact this user. Enable the user by switching the toggle to on. Save your changes when you’re done.:  Enter the information needed to catalog and contact this user. Enable the user by switching the toggle to on. Save your changes when you’re done.
Control what this user has access to by adding a permission: Control what this user has access to by adding a permission
Click on “Add permission”.:  Click on “Add permission”.
Select a permission type, which can restrict the permission’s effect to a group or location.:  Select a permission type, which can restrict the permission’s effect to a group or location.
Set the permission.:  Set the permission.
Save your changes when you’re done:  Save your changes when you’re done.
Coupon code: Coupon code
A coupon code is the unique identifier of a coupon, as well as its associated UPC Barcode value.:  A coupon code is the unique identifier of a coupon, as well as its associated UPC Barcode value.
Coupon name: Coupon name
Add a name to your coupon for easier cataloging purposes.: Add a name to your coupon for easier cataloging purposes.
If provided, this amount is the forced price of the item with linked to PLU 1.:  If provided, this amount is the forced price of the item with linked to PLU 1.
If provided, this PLU references the Veloce item that must be ordered to grant right to previous item.:  If provided, this PLU references the Veloce item that must be ordered to grant right to previous item.
If provided and if PLU 2 is provided, this amount is the forced price of the selected item from PLU 2 effect.:  If provided and if PLU 2 is provided, this amount is the forced price of the selected item from PLU 2 effect.
Add the logo of this location for easier cataloging purposes.:  Add the logo of this location for easier cataloging purposes.
Sales: Sales
Hours: Hours
Logo for this group: Logo for this group
No data available: No data available
Png or jpg file, 600 x 400 pixels (landscape), 72 dpi.:  Png or jpg file, 600 x 400 pixels (landscape), 72 dpi.
Image requirements: Image requirements
You can attribute one of 3 types to a group to help cataloging: You can attribute one of 3 types to a group to help cataloging
Refers to one or more locations linked to the same franchise.:  Refers to one or more locations linked to the same franchise.
Regroups more than one brand under one bigger entity.:  Regroups more than one brand under one bigger entity.
Personalized collection of groups.:  Personalized collection of groups.
Show %s %s:  Show selection
Hide %s %s:  Hide selection
Check no.%s: Check no.{invoiceNumber}
Created by %s / Date/Time %s:  Created by {username} / Date/Time {created}
Back to check summary: Back to check summary
Group locations: Group locations
Add or remove locations for this group. A location can be part of more than one group.:  Add or remove locations for this group. A location can be part of more than one group.
Enable multiselect to choose more than one or all locations.:   Enable multiselect to choose more than one or all locations.
Select one or more locations:   Select one or more locations
Access the list of users with permissions for this location:   Access the list of users with permissions for this location.
Check details: Check details
Browse and review specific checks.:  Browse and review specific checks.
Search: Search
Select status: Select status
check_status:
  0: ""
  1: "Voided"
  2: "Training"
Upload: Upload
User: User
Access this user for modifications.:  Access this user for modifications.
Actions: Actions
Search for specific info, export your list, etc.:  Search for specific info, export your list, etc.
Dashboard options: Dashboard options
Choose the settings and customize your dashboard.:  Choose the settings and customize your dashboard.
Export users list: Export users list
Export: Export
Export coupons list: Export coupons list
Select an option for your export.:  Select an option for your export.
Gross sale: Gross sale
Category: Category
Sub-category: Sub-category
Category total: Category total
Average / Customer: Average / Customer
Service charges: Service charges
Total gross sales: Total gross sales
Total revenue: Total revenue
Gross sales: Gross sales
Total customer count: Total customer count
Subtotal (Net + Taxes + Charges): Subtotal (Net + Taxes + Charges)
Total: Total
Taxable sales: Taxable sales
Total net sales: Total net sales
Quantity: Quantity
Choose currency: Choose currency
Out of balance (Total revenue - Subtotal): Out of balance (Total revenue - Subtotal)
Site status options: Site status options
Choose the settings.:  Choose the settings.
Search for specific informations in your list.:   Search for specific informations in your list.
Order mode: Order mode
Customers: Customers
Workstations: Workstations
Departments: Revenue centers
Discounts volume: Discounts volume
Refresh locations list: Refresh locations list
Refresh groups list: Refresh groups list
Are you sure you want to reset your dashboard customization settings? Any change you may have made to your dashboard will be lost by performing this action.:  Are you sure you want to reset your dashboard customization settings? Any change you may have made to your dashboard will be lost by performing this action.
Compare the selected date with a previous set of data.: Compare the selected date with a previous set of data.
Last week: Last week
Last month: Last month
Last 3 months: Last 3 months
Last year: Last year
year: Last year
quarter: Last 3 months
month: Last month
week: Last week
day: Yesterday
Text alignment: Text alignment
Choose the presentation for the dashboard's data.: Choose the presentation for the dashboard's data.
Reset settings: Reset settings
Dashboard settings: Dashboard settings
Configure your dashboard with these options.:   Configure your dashboard with these options.
Align left: Align left
Align right: Align right
Align center: Align center
Vertical compact: Vertical compact
Layout should be compact vertically.: Layout should be compact vertically.
Total discounts: Total discounts
Payfacto. All rights reserved.:  Payfacto. All rights reserved.
Meals count: Meals count
Checks: Checks
checks: checks
Clients: Clients
Terms & Conditions: Terms & Conditions
Select language:  Select language
Time format: Time format
Time display: Time display
Select your preferred default language.:   Select your preferred default language.
Enter your current password and your new password.:   Enter your current password and your new password.
Profile settings:   Profile settings
User profile:   User profile
Behavior:   Behavior
Set your log-in credentials and your contact email address.:   Set your log-in credentials and your contact email address.
Change password:   Change password
Select your time zone and default date and time format.:   Select your time zone and default date and time format.
Personal info:   Personal info
Confirm new password:   Confirm new password
New password:   New password
Current password:   Current password
Change your password, language settings, etc.:   Change your password, language settings, etc.
Date format:   Date format
Time zone:   Time zone
Interface main language:   Interface main language
%s locations: "{count} location(s)"
Funding: Funding
Redeem: Redeem
OutstandingLiability: Outstanding Liability
Check no.: Check no.
Card number: Card number
Refund: Refund
Operation: Operation
Consolidated: Consolidated
Discount name: Discount name
Nothing to see here for now. Enter the required information to generate the report.: Nothing to see here for now. Enter the required information to generate the report.
Activation: Activation
Refill: Refill
Redemption Void: Redemption Void
Redemption: Redemption
Activation Void: Activation Void
Refill Void: Refill Void
Point Add: Point Add
Point Void: Point Void
Point Add Void: Point Add Void
Point Activate: Point Activate
dates: dates
discounts: discounts
gift cards: gift cards
Confirmation password doesn't match new password: Confirmation password doesn't match new password
24-Hour: 24-Hour
12-Hour: 12-Hour
Data trends: Data trends
Show data trends on dashboard.: Show data trends on dashboard.
Increases are represented by green upward arrows: Increases are represented by green upward arrows
Decreases are represented by red downward arrows: Decreases are represented by red downward arrows
Number of results on the graphs: Number of results on the graphs
Select the type of display for your data.: Select the type of display for your data.
Type of chart to display by default: Type of chart to display by default
List: List
Pie: Pie
Bar: Bar
%s employees: "All employees(s)"
All %: All %
Item %: Item %
Gross sales volume: Gross sales volume
Revenue center breakdown: Revenue center breakdown
revenue centers: revenue centers
invoices: invoices
employees: employees
French: French
English: English
Current password doesn't match your account password: Current password doesn't match your account password
Enter a password: Enter a password
At least one uppercase: At least one uppercase
At least one lowercase: At least one lowercase
At least one numeric: At least one numeric
Minimum 8 characters: Minimum 8 characters
This year: This year
This month: This month
This week: This week
Today: Today
Period: Period
Authorized by: Authorized by
Item description: Item description
Item price: Item price
The main and secondary descriptions enable you to specify the default and alternative languages for displaying data, respectively. Choose which language to use when rendering the data.: The main and secondary descriptions enable you to specify the default and alternative languages for displaying data, respectively. Choose which language to use when rendering the data.
Main description: Main description
Secondary description: Secondary description
Gross sales (Top X): Gross sales (Top {top})
Voids: Voids
Refunds: Refunds
Corrections: Corrections
Gift-Card-Sales-Report: Gift-Card-Sales-Report
Employees-Sales-Report: Employees-Sales-Report
Discount-Report: Discount-Report
Voids-Report: Voids-Report
Net-Sales-Report: Net-Sales-Report
Employee number: Employee number
January: January
February: February
March: March
April: April
May: May
June: June
July: July
August: August
September: September
October: October
November: November
December: December
Last day: Last day
Report settings: Report settings
Configure your report with these options.: Configure your report with these options.
Report display: Report display
Select the type of report you want to display.: Select the type of report you want to display.
Heatmap settings: Heatmap settings
Select the data type you wish to display on the heatmap graph.: Select the data type you wish to display on the heatmap graph.
Number of checks: Number of checks
Average / Check: Average / Check
Data table: Data table
Heatmap: Heatmap
Hourly-Sales-Report: Hourly-Sales-Report
Category-Sales-Report: Category-Sales-Report
Number of customers: Number of customers
Income per customer: Gross income per customer
No sales: No sales
X to X: "{from} to {to}"
Sales / Order mode: Sales / Order mode
By clicking on the Login button, or by accessing or otherwise using the service, you agree to be bound by the:  By clicking on the <b>Login</b> button, or by accessing or otherwise using the service, you agree to be bound by the
Coming soon!: Coming soon!
This feature is under construction.: This feature is under construction.
Delivery: Delivery
Delivery Customers: Delivery Customers
Delivery Amount: Delivery Amount
Takeout: Takeout
Takeout Customers: Takeout Customers
Takeout Amount: Takeout Amount
Table Customers: Table Customers
Table Amount: Table Amount
Quick Service: Quick Service
Quick Service Customers: Quick Service Customers
Quick Service Amount: Quick Service Amount
Refunds & Voids: Refunds & Voids
Cash: Cash
Gross Sales / Workstation: Gross Sales / Workstation
Tender Types: Tender Types
Queue Lane: Queue Lane
Queue Lane Customers: Queue Lane Customers
Queue Lane Amount: Queue Lane Amount
Running Bill: Running Bill
Running Bill Customers: Running Bill Customers
Running Bill Amount: Running Bill Amount
Refunds Quantity: Refunds Quantity
Refunds Total: Refunds Total
Refunds % of sales: Refunds % of sales
Voids Quantity: Voids Quantity
Voids % of sales: Voids % of sales
Item correction Quantity: Item correction Quantity
Item correction Total: Item correction Total
Cash Deposit: Cash Deposit
Paidouts:   Paidouts
Tips: Tips
Gift load: Gift load
Loyalty sales: Loyalty sales
Cash total: Cash total
Voids Total: Voids Total
You have changed the settings for your rapport. Click on the Refresh button to generate a report with these settings.: You have changed the settings for your rapport. Click on the Refresh button to generate a report with these settings.
Please refresh!: Please refresh!
sub-categories: sub-categories
categories: categories
Items-Sales-By-Category-Report: Items-Sales-By-Category-Report
All Percentage: All Percentage
Item Percentage: Item Percentage
Average Daily amount: Average Daily amount
Total Amount: Total Amount
Total Quantity: Total Quantity
Summary settings: Summary settings
Configure your summary with these options.: Configure your summary with these options.
Temporarily Unavailable: Temporarily Unavailable
Compact dashboard vertically: Compact dashboard vertically
Compact dashboard: Compact dashboard
Are you sure you want to proceed with compacting the dashboard tiles? This action will rearrange and optimize the layout of your dashboard tiles for better space utilization.: Are you sure you want to proceed with compacting the dashboard tiles? This action will rearrange and optimize the layout of your dashboard tiles for better space utilization.
Select a value: Select a value
No data: No data
We have no data to show you for these report settings.: We have no data to show you for these report settings.
Taxes total: Taxes total
Charges: Charges
Charge: Charge
Charges total: Charges total
Consent preferences: Consent preferences
Options: Options
Choose the settings and customize your report.: Choose the settings and customize your report.
The gross sales by revenue center will be grouped based on the check.: The gross sales by revenue center will be grouped based on the check.
The gross sales by revenue center will be grouped based on the items listed on the check.: The gross sales by revenue center will be grouped based on the items listed on the check.
Connection Lost: Connection Lost
The internet connection has been lost and that some functionalities may not be available until the connection is restored.: The internet connection has been lost and that some functionalities may not be available until the connection is restored.
We noticed you are currently using a previous version of Veloce Analytics. Refresh your page to access new features and improvements.: We noticed you are currently using a previous version of Veloce Analytics. Refresh your page to access new features and improvements.
New Veloce version available!: New Veloce version available!
Refresh now: Refresh now
No thanks, I will refresh later.: No thanks, I will refresh later.
Continue: Continue
The revenue center is based on the check.: The revenue center is based on the check.
Comparison period: Comparison period
Show last version: Show last version
Show previous version: Show previous version
Gross sales by revenue center are grouped by invoice items.: The gross sales by revenue center will be grouped based on the items listed on the check.
Retain gratuity for payroll: Retain gratuity for payroll
This option determines whether the gratuity amount will be deducted from the cash total or from the payroll.: This option determines whether the gratuity amount will be deducted from the cash total or from the payroll.
Date and time of the last activity on the server for the location in local time.: Date and time of the last activity on the server for the location in local time.
Date and time of the most recent check in local time of the location.: Date and time of the most recent check in local time of the location.
Dates: Dates
Modes: Modes
New Version Available: New Version Available
Try the new Veloce Analytics look: Try the new Veloce Analytics look
New Veloce Analytics Look: New Veloce Analytics Look
Click on this toggle to enable the new Veloce Analytics UI design.: Click on this toggle to enable the new Veloce Analytics UI design.
"% of sales": % of sales
Sales last year: Sales last year
"% to last year sales": % to last year sales
Meals: Meals
"% to last year meals": % to last year meals
Revenue Center: Revenue Center
Sales by Modes: Sales by Modes
Sales by Category: Sales by Category
Sales by Revenue Center: Sales by Revenue Center
Tips & Change: Tips & Change
Trans Count: Trans Count
Cash Reconciliation: Cash Reconciliation
Total sales: Total sales
Gross sales + Tax: Gross sales + Tax
Tax: Tax
Discount Summary (Amount net of tax): Discount Summary (Amount net of tax)
Tax Summary: Tax Summary
Item correction: Item correction
Avg Meals: Avg Meals
Statistics: Statistics
Item corrections: Item corrections
Average: Average
Count: Count
What's New?: What's New?
Required if no invoice numbers specified: Required if no invoice numbers specified
Required if no date range specified. Multiple invoices can be entered seperated by a ','. Invoices range can be entered separated by a '-': Required if no date range specified. Multiple invoices can be entered seperated by a ','. Invoices range can be entered separated by a '-'
Consolidated Sales: Consolidated Sales
Net Paid Ins/Outs: Net Paid Ins/Outs
Paid In/Out Summary: Paid In/Out Summary
itemCorrections: Item correction
refunds: Refunds
voids: Voids
Summary report: Summary report
Detailed report: Detailed report
noSales: No sales
Report presets: Report presets
Save as: Save as
My report presets: My report presets
Select a set of saved presets for your report.: Select a set of saved presets for your report.
There are no saved presets.: There are no saved presets.
Save presets as: Save presets as
Are you sure you want to delete these presets?: Are you sure you want to delete these presets?
Delete presets: Delete presets
Rename presets: Rename presets
Check average: Check Average
Waiter Performance: Employee performance
Employee name: Employee name
Summary Sales: Summary Sales
Generate a performance report for each waiter.: Generate a performance report for each employee.
Generate a summarizing categories report.: Generate a summarizing categories report.
Review sales reports by employees.: Review sales reports by employees.
Report filters: Report filters
My report filters: My report filters
Select a set of saved filters for your report.: Select a set of saved filters for your report.
There are no saved filters.: There are no saved filters.
Are you sure you want to delete these filters?:   Are you sure you want to delete these filters?
Rename filters: Rename filters
Save filters as: Save filters as
Additionnal fees: Additionnal fees
Gratuity: Gratuity
Employee-Performance-Report: Employee-Performance-Report
vendus: sold
Nothing to see here for now. Enter the required information to generate the employee performance.: Nothing to see here for now. Enter the required information to generate the employee performance.
Previous week sales %s: Previous week sales ({from})
Previous month sales %s: Previous month sales ({from})
Previous year sales %s: Previous year sales ({from})
Sales %s: Sales ({from})
Previous week sales %s %s: Previous week sales ({from} to {to})
Previous month sales %s %s: Previous month sales ({from} to {to})
Previous year sales %s %s: Previous year sales ({from} to {to})
Sales %s %s: Sales ({from} to {to})
Total Discounts: Total Discounts


